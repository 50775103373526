import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
import moment from "moment";

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.privateGraphql.getnew
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.short}
          image={`${post.image}`}
          isBlogPost={true}
          datePublished={post.publishDate}
          authorName={post.profileName}
          authorUrl={`${siteUrl}/authors/${post.profileSlugName}`}
          authorSocial={Author.twitter}
        />
        <div className="col-lg-8 col-md-12 spm">
          <article className="archive-post mts-post spp">
            <header className="post-content">

              <div className="post-meta">
                <h1 className="w-article-header__headline">{post.title}</h1>
                <span className="theauthor">
                  <Link to={`/authors/${Author.slugname}`} title={Author.profilename}>
                    <LazyLoadImage
                      className="author-avatar"
                      title={Author.profilename}
                      alt={Author.profilename}
                      src={Author.image}
                      effect="blur"
                    />
                    {Author.profilename}
                  </Link>
                </span>
                <a href={`${Author.twitter}`} title={"Twitter " + Author.profilename}>Twitter</a>
                <span className="thetime updated">{moment(post.publishDate).format('DD/MM/YYYY h:mm')}</span>
              </div>
            </header>
            <div className="post-entry">
              <div dangerouslySetInnerHTML={{ __html: post.detail }} />
            </div>
            <div className="post-footer">
              {post.slugtags !== null ?
                post.slugtags.split(',').map(tagx => {
                  if (tagx !== "") return (
                    <Link to={`/${(tagx)}`} className="tags-chip" key={tagx}>{tagx}</Link>
                  )
                })
                :
                null
              }
            </div>
          </article>
        
        <div className="row justify-content-center rsp">
          <div className="col-12 text-center item-relate-h">เรื่องที่เกี่ยวข้อง</div>
          <Col xs={12} md={6} className="item-relate">
            {previous && (
              <article className="related-post mts-post">
              <Link to={previous.slugtitle} title={previous.title} className="post-image post-image-related">
                <div className="featured-thumbnail">
                  <img alt= {previous.title} src={ previous.image} />
                </div>
              </Link>
              <div className="post-content">
                <header>
                <span className="post-title"><a href={`${siteUrl}/`+previous.slugtitle} title={previous.title}>{previous.title}</a></span>
                </header>
                <div className="post-excerpt" dangerouslySetInnerHTML={{ __html: previous.short }} />
              </div>
            </article>
            )}

          </Col>
          <Col xs={12} md={6} className="item-relate">
            {next && (
              <article className="related-post mts-post clearfix">
                <Link to={next.slugtitle} title={next.title} className="post-image post-image-related">
                  <div className="featured-thumbnail">
                    <img alt={next.title} src={ next.image} />
                  </div>
                </Link>
                <div className="post-content">
                  <header>
                  <span className="post-title"><a href={`${siteUrl}/`+next.slugtitle} title={next.title}>{next.title}</a></span>
                  </header>
                  <div className="post-excerpt" dangerouslySetInnerHTML={{ __html: next.short }} />
                </div>
              </article>
            )}
          </Col>
        </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String = "404",$siteId: Int = 1066) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getauthorbynewslugtitle(slugtitle: $slug, siteId: $siteId) {
        id
        profilename
        twitter
        slugname
        image
        bio
      }
      getnew(slugtitle: $slug, siteId: $siteId) {
        id
        detail
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
      }
    }
  }
`